$header-padding-x: 0rem;
$header-padding-y: 0rem;
$header-border-color: none;

$sidebar-bg: linear-gradient(180deg, #171821 25%, #39465D 100%);
$sidebar-brand-bg: #373737;

$sidebar-nav-link-active-bg: #EE6990;
$sidebar-nav-link-hover-bg: #EE6990;
$sidebar-nav-link-border-radius: 0.625rem;
$card-border-color: #fff;
$progress-height: 0.5889rem !important;
$progress-bg: rgba(255, 255, 255, 0.50);
$border-radius: 0.625rem;

$pagination-active-bg: #EE6990;
$pagination-active-border-color: #EE6990;
$pagination-color: #000;

.rdrStartEdge,
.rdrEndEdge,
.rdrInRange {
    color: #EE6990 !important;
}

.rdrDateDisplayItemActive,
.rdrDayHovered {
    border-color: #EE6990 !important;
}



.progress-bar {
    border-radius: 0.375rem;
}

.progressbar1 {
    .progress-bar {
        background-color: #EE6990;
    }
}

.progressbar2 {
    .progress-bar {
        background-color: #7379DC;
    }
}

.progressbar3 {
    .progress-bar {
        background-color: #4EB981;
    }
}

.progressbar4 {
    .progress-bar {
        background-color: #EEC33F;
    }
}

.progressbar5 {
    .progress-bar {
        background-color: #7075D9;
    }
}

.progressbar6 {
    .progress-bar {
        background-color: #E6BA34;
    }
}

.progressbar7 {
    .progress-bar {
        background-color: #FFFFFF;
    }
}

.progressbar8 {
    .progress-bar {
        background-color: #46A1FA;
    }
}

.skinWidget1 {
    background-color: #7D82E0 !important;
}

.skinWidget2 {
    background-color: #5BC18C !important;
}

.skinWidget3 {
    background-color: #EDC23D !important;
}

.skinWidget4 {
    background-color: #4F9FEE !important;
}

.skinWidget5 {
    background-color: #FE6584 !important;
}

.skinIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
}

.skinIcon1 {
    position: absolute;
    top: 0;
    left: 85%;
    width: 3rem;
    height: 3rem;
}

.widget {
    padding: 1.25rem;
    border-radius: 0.625rem;
    color: #fff;
}

.widgetSkin {
    padding: 0.625rem;
    border-radius: 0.625rem;
    color: #fff;
    flex-basis: 20%;
    background-color: #f0f0f0;
    border: 0.0625rem solid #ccc;
    text-align: center;
    box-sizing: border-box;
}

.widget1 {
    background: linear-gradient(225deg, #3699FA 0%, rgba(106, 179, 248, 0.70) 100%);
}

.widget2 {
    background: linear-gradient(224deg, #50BA83 0%, rgba(131, 216, 172, 0.70) 100%);
}

.widget3 {
    background: linear-gradient(224deg, #6B71D7 0%, rgba(153, 157, 240, 0.70) 100%);
}

.widget4 {
    background: linear-gradient(224deg, #E6B932 0%, rgba(253, 217, 91, 0.70) 100%);
}

.widget5 {
    background: linear-gradient(224deg, #FE6584 0%, rgba(255, 137, 161, 0.70) 89.08%);
}

.widget6 {
    background: linear-gradient(224deg, #5ABF8B 0%, #7ce7b0 89.08%);
}

.widget7 {
    padding: 1.25rem;
    border-radius: 0.625rem 0rem 0rem 0.625rem;
    background: #FFF;
    flex-basis: 20%;
}


.cardBody {
    height: 33.5rem;
    overflow: auto;
    padding: 0.625rem;

    .progress {
        background: #D4D4D4;
    }
}

.whiteCardProgress {
    .progress {
        background: #D4D4D4;
    }
}

.cardHover {
    cursor: pointer;
}

.cardHover:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

// select store dropdown start

.dropdown {
    position: relative;
    display: inline-block;
}

.selected-option {
    padding: 0.625rem 1.875rem;
    color: #373737;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: inline-block;
}

.selected-option1 {
    color: #202020 !important;
    font-size: 1.375rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding: 0rem 1rem 0 !important;
}

.selected-option.open {
    background-color: #E1E1E1;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 25rem;
    overflow-y: auto;
    background-color: #fff;
    border: 0.0625rem solid #ccc;
    border-top: none;
    z-index: 1;
    border-radius: 0rem 0rem 0.625rem 0.625rem;
    box-shadow: -0.0625rem 0.125rem 0.3125rem 0rem rgba(0, 0, 0, 0.10);
    text-align: center;

}

.optionsNested {
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
    // max-height: 25rem;
    overflow-y: auto;
    background-color: #fff;
    border: 0.0625rem solid #ccc;
    border-top: none;
    z-index: 1;
    border-radius: 0.625rem 0rem 0.625rem 0.625rem;
    box-shadow: -0.0625rem 0.125rem 0.3125rem 0rem rgba(0, 0, 0, 0.10);
    text-align: center;
    padding-top: 0.625rem;
}


.option {
    color: #BDBDBD;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: normal;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
}

.option.selected {
    color: #373737;
}

.option:hover {
    background-color: #f0f0f0;
}

// select store dropdown end

.cardTitleDashboard {
    color: #444;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0.9375rem;
}

.storeNumber {
    color: #444;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.storeSubTitle {
    color: #9C9C9C;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.9375rem 0 0.625rem 0
}

.subTitleDashboard {
    color: #444;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0.625rem;
}

.consumerSubtitle {
    color: #9C9C9C;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0.625rem;

}

.sidebarLinkName {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dashboardCard {
    border-radius: 3rem;
    background: #FFF;
    padding: 0.3rem 1.25rem;
}

.dashboardType {
    color: #B9BDC2;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 3.125rem;
    cursor: pointer;
}

.dashboardType.selected {
    color: #EE6990;
    font-weight: 700;
}

.withRightBorder {
    border-right: 0.2231rem solid #D9D9D9;
}

.profileDiv {
    padding: 1rem 10rem 2rem 2rem;
}

.profileTitle {
    color: #000;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 0.625rem;
}

.profileSubtitle {
    color: #4B4B4B;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profileSubtitle1 {
    color: #4B4B4B;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 0.2rem;
}

.profileKey {
    color: #202020;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 0.625rem;
}

.profileValue {
    border-radius: 0.6875rem;
    border: 0.0625rem solid #202020;
    margin: auto;
    width: 50%;
    padding: 0.625rem 1.875rem;
}

.profileImg {
    border-radius: 50%;
}

.deleteBtn {
    color: #4B4B4B;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: transparent;
}

.updateBtn {
    color: #EE6990;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: transparent;
}

.resetBtn {
    color: #EE6990;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: transparent;
}

.ratingsTitle {
    color: #202020;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 1.25rem;
}


.ratings {
    margin-right: 0.625rem;
}

.ratings i {
    color: #cecece;
    font-size: 1.5rem;
}

.rating-color {
    color: #fbc634 !important;
}

.rating-color-table {
    color: #7A7A7A !important;
}

.ratingCard {
    padding: 0rem 6.25rem;
}

.performanceTitle {
    color: #202020;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.ratingNumber {
    color: #202020;
    font-size: 5.3125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.1062rem;
}

.performanceSubtitle {
    color: #A4A4A4;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.circularProgressSubtitle {
    color: #202020;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ratingContainer {
    display: flex;
    flex-direction: column;
    height: 85vh;
    gap: 0.25rem;
    padding-top: 1.25rem;

    .ratingContainer1 {
        flex: 20%;
        padding-bottom: 1.25rem;

    }

    .ratingContainer2 {
        flex: 46%;
        border-radius: 0.625rem;
        background: #FFF;
    }
}

// Rating Table start

.skuTableContainer,
.mirrorStatus,
.ratingContainer2 {
    table {
        border-collapse: collapse;
        border-radius: 0.625rem;
        overflow: hidden;
        height: 100%;
    }

    tbody {
        display: block;
        height: 87%;
        overflow: auto;
        border-radius: 0 0 0.625rem 0.625rem;
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    thead {
        width: 100%;
    }

    table>thead>tr>th {
        border-bottom: 0.125rem solid #EAEAEA !important;
        background: #EAEAEA !important;
        height: 4rem !important;
        padding-bottom: 0.8rem !important;
    }

    table>tbody>tr>td {
        border-bottom: 0.125rem solid #EAEAEA !important;
        height: 4.375rem !important;
    }
}




.tableLabel {
    color: #202020 !important;
    font-size: 1.375rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding: 0.25rem 1.25rem 0.25rem 0 !important;
}

.storeLabel {
    color: #A4A4A4 !important;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    vertical-align: middle;
}

// Rating Table end

.mirrorStatus {
    height: 60vh;
    width: 86vw;
    margin: auto;
    border-radius: 0.625rem;
}

.mirrorStatus tbody {
    height: 90%;
}

.mirrorLabel {
    color: #202020;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    vertical-align: middle;
    padding: 0.25rem 1.25rem 0.25rem 0 !important;
}

.skuTableContainer {
    height: 60vh;
    width: 80vw;
    margin: auto;

    table>thead>tr>th {
        border-bottom: 0.125rem solid #AAA !important;
        background: #fff !important;
        height: 4rem !important;
    }

    table>tbody>tr>td {
        border-bottom: none !important;
        height: 4.375rem !important;
    }
}

.skuContainer {
    height: 85vh;
    width: 82vw;
    margin: auto;
}

.skuSubContainer {
    background: #fff;
    border-radius: 0.625rem;
}

.searchContainer {
    height: 100%;
    width: auto;
    background: #EAEAEA;
    border-radius: 0.625rem 0.625rem 0 0;
    padding: 1rem 10rem;
}

// Search box start 

.form {

    position: relative;
}

.form .fa-search {

    position: absolute;
    top: 0.625rem;
    left: 1.25rem;
    color: #9ca3af;

}

.left-pan {
    padding-left: 0.4375rem;
}

.left-pan i {

    padding-left: 0.625rem;
}

.form-input {
    height: 2.5rem;
    text-indent: 2.0625rem;
    border-radius: 0.625rem;
}

.form-input::placeholder {
    color: #D3D3D3;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form-input:focus {

    box-shadow: none;
    border: none;
}

// Search box end


.totalNumberOfUser,
.totalNumberOfSession,
.productRecommendation,
.countOfSmsWapp {
    .chart-wrapper {
        height: 31.25rem;
    }
}

// .custom-scrollbar::-webkit-scrollbar {
//     width: 0.5em;
// }

// .custom-scrollbar::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 10px;
// }

// .custom-scrollbar::-webkit-scrollbar-thumb {
//     background-color: darkgrey;
//     // outline: 1px solid slategrey;
//     border-radius: 10px;
// }


.resetPassword {
    max-width: 31.25rem;
    margin: auto;
}

.forgotPassword {
    max-width: 100%;
    margin: auto;
    padding: 12.5rem 25rem;
}

.downloadBtn {
    padding: 0.3125rem 0.625rem;
    background: rgba(238, 105, 144, 1);
    border: none;
    color: rgba(255, 255, 255, 1);
    border-radius: 0.375rem;
    font-size: 1.125rem;
}