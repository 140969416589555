@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  font-size: 16px;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
  border-radius: 10px;
}


body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 2000px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 11px;
  }
}